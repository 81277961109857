import React from "react";
import {AccountSettings} from '@aws-amplify/ui-react';
import {I18n} from "aws-amplify";

I18n.putVocabularies({
    fr: {
        'Current Password': 'Mot de passe actuel',
        'New Password': "Nouveau mot de passe",
        'Confirm Password': "Confirmez le mot de passe",
        'Update password': "Mettre à jour le mot de passe",
        'Password must have at least 8 characters': "Le mot de passe doit comporter au moins 8 caractères",
        'Password must have upper case letters': "Le mot de passe doit contenir des lettres majuscules",
        'Password must have numbers': "Le mot de passe doit contenir des chiffres",
        'Password must have special characters': "Le mot de passe doit contenir des caractères spéciaux",
        'Your passwords must match': "Vos mots de passe doivent correspondre",
    },
});

function Password() {

    const components = {
        NewPasswordField: (props) => (
            <AccountSettings.ChangePassword.NewPasswordField
                {...props}
                label="New Password"
            />
        ),
        ConfirmPasswordField: (props) => (
            <AccountSettings.ChangePassword.ConfirmPasswordField
                {...props}
                label="Confirm Password"
            />
        ),
    }

    return (
        <>
            <br/><br/>
            <AccountSettings.ChangePassword components={components}/>

        </>
    )
}

export default Password;
