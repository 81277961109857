import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from "./home/home";
import Passe from "./passes";
import Pagenotfound from "./404";
import Dashboard from "./admin/dashboard";
import Create from "./admin/create";
const routes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/passes" element={<Passe/>} />
            <Route path="*" element={<Pagenotfound/>} />
            <Route path="/config" element={<Dashboard/>} />
            <Route path="/config/update/:id" element={<Create/>} />
        </Routes>
    )
}

export default routes
