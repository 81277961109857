import "./components/assets/css/index.css";
import {BrowserRouter} from "react-router-dom";
import Footer from "./components/footer";
import Routes from "./components/routes";
import Logo from './components/assets/images/logo.svg';
import {Container, Nav, Navbar} from "react-bootstrap";

function App() {
    return (
        <>
            <BrowserRouter>
                <Navbar bg="light" expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img src={Logo} alt="logo" style={{height: "70px"}}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                            </Nav>
                            <Nav>
                                <Nav.Link href="/">Acceuil</Nav.Link>
                                <Nav.Link href="/#step">Pas de bases</Nav.Link>
                                <Nav.Link href="/passes">Les passes</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>


                <Routes/>

                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;
