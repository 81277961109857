import React, {Component} from "react";
import {Card} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {DataStore} from '@aws-amplify/datastore';
import {Passes} from '../models';

class Passe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deplacements: [],
            ouvertures: [],
            sorties: [],
            rotations: [],
            breaks: [],
        };
    }

    loadData = async () => {
        const sub = DataStore.observeQuery(Passes);
        const models = await DataStore.query(Passes, (c) => c.category.eq('ouvertures'));
        this.setState({ouvertures: models})
        const place = await DataStore.query(Passes, (c) => c.category.eq('deplacements'));
        this.setState({deplacements: place})
        const sort = await DataStore.query(Passes, (c) => c.category.eq('sorties'));
        this.setState({sorties: sort})
        const rotatate = await DataStore.query(Passes, (c) => c.category.eq('rotations'));
        this.setState({rotations: rotatate})
        const breaks = await DataStore.query(Passes, (c) => c.category.eq('breaks'));
        this.setState({breaks: breaks})
    }

    componentDidMount() {
        this.loadData();
    }

    render() {

        const ouverture = this.state.ouvertures;
        const deplacements = this.state.deplacements;
        const sorties = this.state.sorties;
        const rotations = this.state.rotations;
        const breaks = this.state.breaks;

        return (
            <>
                <div className="container text-center" style={{marginTop: "3%"}}>
                    <div className="base_header">
                        <span>
                          <small className="bor_header_left"></small>Les passes
                          <small className="bor_header_right"></small>
                        </span>
                    </div>
                    <br/>
                    <div className="container">
                        <h3>Les ouvertures</h3>
                        <div className="row">
                            {ouverture?.map((ouvert, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card key={key}>
                                        <Card.Body>
                                            <Card.Title>{ouvert.name}</Card.Title>
                                            <Card.Text>
                                                {ouvert.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {ouvert.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container" style={{marginTop: "5%"}}>
                        <h3>Les changements de places</h3>
                        <div className="row">
                            {deplacements?.map((deplacement, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card id={key}>
                                        <Card.Body>
                                            <Card.Title>{deplacement.name}</Card.Title>
                                            <Card.Text>
                                                {deplacement.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {deplacement.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container" style={{marginTop: "5%"}}>
                        <h3>Les Rotations</h3>
                        <div className="row">
                            {rotations?.map((rotation, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card>
                                        <Card.Body key={key}>
                                            <Card.Title>{rotation.name}</Card.Title>
                                            <Card.Text>
                                                {rotation.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {rotation.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container" style={{marginTop: "5%"}}>
                        <h3>Les sorties</h3>
                        <div className="row">
                            {sorties?.map((sortie, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card id={key}>
                                        <Card.Body>
                                            <Card.Title>{sortie.name}</Card.Title>
                                            <Card.Text>
                                                {sortie.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {sortie.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container" style={{marginTop: "5%"}}>
                        <h3>Les Breaks</h3>
                        <div className="row">
                            {breaks?.map((brea, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card id={key}>
                                        <Card.Body>
                                            <Card.Title>{brea.name}</Card.Title>
                                            <Card.Text>
                                                {brea.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {brea.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
            </>
        )
            ;
    }
}

export default Passe;
