import React, {Component} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Contact from './contact';
import image1 from '../assets/images/image1.png';
import {Card} from "react-bootstrap";
import { DataStore } from 'aws-amplify';
import { Passes } from '../../models';
import {Hub} from "aws-amplify";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passes: [],
        };
    }

    loadData = async () =>{
        // const sub = DataStore.observeQuery(Passes).subscribe(({items}) =>{
        //     this.setState({passes: items})
        // });

        const removeListener = Hub.listen("datastore", async (capsule) => {
            const {
                payload: { event },
            } = capsule;
            console.log(event)
            if (event === "ready") {
                const passe = await DataStore.query(Passes);
                console.log(await DataStore.query(Passes))
                this.setState({passes : passe});
            }
        });

        await DataStore.start();

        return () => {
            removeListener();
        };
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const {passes} = this.state;
        return (
            <>
                <div className="container" style={{marginTop: '3%'}}>
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="container"><img src={image1} style={{width: '100%'}}/></div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="container" style={{marginTop: '7%'}}>
                                <div className="base_header"><span><small className="bor_header_left"></small>Ramboubou Dance Floor<small
                                    className="bor_header_right"></small></span>
                                    <h3>C'est quoi exactement ?</h3>
                                </div>
                                <div className="base_footer">
                                    <p>Une compilation de nos cours de danse, permettant de se souvenir des mouvements,
                                        et des les apprendre via cette interface. Peut être
                                        un moyen efficace de réviser vos mouvements avant d’aller en soirée Rock !</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="steps" id="step" style={{marginTop: '3%'}}>
                    <div className="row">
                        <div>
                            <div className="container">
                                <div className="base_header text-center"><span><small
                                    className="bor_header_left"></small>Le rock à 6 temps<small
                                    className="bor_header_right"></small></span>
                                    <h3>Les pas de base</h3>
                                </div>
                                <div className="base_footer" key="1">
                                    <p><strong>Petite histoire</strong> <br/>
                                        On danse le rock à 6 temps plus souvent sur des musiques au rythme rock.
                                        Le terme « six temps » correspond à six temps musicaux, mais comporte huit
                                        appuis.
                                        <br/>
                                        Les appuis se comptent ainsi : « un, deux, trois et quatre, cinq et six ».
                                        Chaque mot (y compris les deux « et ») correspond à un appui.
                                        <br/>
                                        <br/>
                                        Sur la ligne de danse, le pas de base est, pour le cavalier (avec entre
                                        parenthèses, le comptage sur la musique) :
                                        <br/>
                                        - un rock-step : pied gauche légèrement en arrière, puis pied droit sur place
                                        (un-deux) ;
                                        <br/>
                                        - un chassé vers la gauche : gauche-droite-gauche (trois et quatre) ;
                                        <br/>
                                        - un chassé vers la droite : droite-gauche-droite (cinq et six).
                                        <br/>
                                        Puis on recommence : pied gauche en arrière, etc.
                                        <br/>
                                        Pour la cavalière, les pas sont en miroir par rapport au cavalier (rock-step
                                        pied-droit en arrière, puis droite-gauche-droite, gauche-droite-gauche, etc.)
                                        <br/>
                                        <br/>
                                        Sur un rythme rapide, pour éviter le piétinement, il est possible de simplifier
                                        les pas :
                                        au lieu de danser « un, deux, trois et quatre, cinq et six », il
                                        suffit de marquer « un, deux, pas, pas, pas, pas », où deux « pas » remplacent
                                        le triple piétinement et durent deux temps musicaux.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>

                <div className="container text-center" style={{marginTop: '5%'}}>
                    <div className="base_header"><h3>Les passes</h3>
                        <span style={{padding: '2%'}}><a href="/passes">Plus de passes →</a></span>
                    </div>
                    <br/>
                    <div className="container ">
                        <div className="row">
                            {passes.filter((passe, key )=> key < 3).map((passe, key) => (
                                <div
                                    className="col-md-6 col-lg-4 col-xl-4"
                                    style={{marginTop: "2%"}}
                                >
                                    <Card>
                                        <Card.Body key={key}>
                                            <Card.Title>{passe.name}</Card.Title>
                                            <Card.Text>
                                                {passe.description}
                                            </Card.Text>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Plus de détails</Accordion.Header>
                                                    <Accordion.Body>
                                                        {passe.explanation}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>

                <Contact/>

                <div className="container" style={{marginTop: '3%'}}>
                    <div className="row">
                        <div>
                            <div className="container">
                                <div className="base_header text-center"><span><small
                                    className="bor_header_left"></small>De la musique<small
                                    className="bor_header_right"></small></span>
                                    <h3>Petite playlist pour danser</h3>
                                </div>
                                <div className="base_footer" style={{marginTop: '3%'}}>
                                    <iframe style={{borderRadius:12}}
                                            src="https://open.spotify.com/embed/playlist/6lGvacoR0eZRZjEgDaBGj2?utm_source=generator"
                                            width="100%" height="580" frameBorder="0" allowFullScreen=""
                                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                            loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>

            </>
        );
    }
}

export default Home;