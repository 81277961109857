import React, {Component} from "react";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {Passes} from "../../models";
import {DataStore, Predicates, SortDirection} from "aws-amplify";
import {AiFillDelete, AiFillEdit} from "react-icons/ai";

function MyVerticallyCenteredModal(props) {
    const passeEdit = props.data;

    async function handleSave(e) {
        const original = await DataStore.query(Passes, passeEdit.id);
        await DataStore.save(
            Passes.copyOf(original, updated => {
                    updated.name = e.target.name.value
                    updated.description = e.target.description.value
                    updated.explanation = e.target.explanation.value
                    updated.category = e.target.category.value
            })
        );
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modification {passeEdit.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSave}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Entrez un nom" defaultValue={passeEdit.name} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" type="text" name="description" placeholder="Description" defaultValue={passeEdit.description} />
                        <Form.Text className="text-muted">
                            Entrez une brève description de la passe
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="explanation">
                        <Form.Label>Explication</Form.Label>
                        <Form.Control as="textarea" type="text" name="explanation" placeholder="Explication" defaultValue={passeEdit.explanation}/>
                        <Form.Text className="text-muted">
                            Expliquez les mouvements complets de la passe
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Catégorie</Form.Label>
                        <Form.Select name="category" defaultValue={passeEdit.category}>
                            <option value="ouvertures">Les ouvertures</option>
                            <option value="deplacements">Les déplacements</option>
                            <option value="rotations">Les rotations</option>
                            <option value="sorties">Les sorties</option>
                            <option value="breaks">Les breaks</option>
                        </Form.Select>
                    </Form.Group>
                    <Button variant="success" type="submit">
                        Modifier
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary">Fermer</Button>
            </Modal.Footer>
        </Modal>
    );
}

class Liste extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passes: [],
            modalShow : false,
            edit : {
                name : '',
                description : '',
                explanation : '',
                category : '',
            }
        };
    }

    handleDelete = async (id) => {
        const modelToDelete = await DataStore.query(Passes, id);
        await DataStore.delete(modelToDelete);
        this.loadData()
    }


    loadData = async () => {
        const models = await DataStore.query(Passes, Predicates.ALL, {
            sort: s => s.category(SortDirection.ASCENDING)
        });
        this.setState({passes: models})
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const passes = this.state.passes;
        return (
            <>
                <div className="container text-center" style={{marginTop: "3%"}}>
                    <div className="base_header">
                        <span>
                          <small className="bor_header_left"></small>Toutes les passes
                          <small className="bor_header_right"></small>
                        </span>
                    </div>
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Description</th>
                            <th>Explication</th>
                            <th>Catégorie</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {passes?.map((passe, key) => (
                            <tr key={key}>
                                <td>{passe.name}</td>
                                <td>{passe.description}</td>
                                <td>{passe.explanation}</td>
                                <td>{passe.category}</td>
                                <td>
                                    <Button onClick={() => this.handleDelete(passe.id)} variant="danger" style={{ marginRight: '1%'}}>
                                        <AiFillDelete/>
                                    </Button>
                                    <Button onClick={() => this.setState({modalShow : true, edit : passe})} variant="info">
                                        <AiFillEdit/>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>

                <MyVerticallyCenteredModal
                    show={this.state.modalShow}
                    onHide={() => this.setState({modalShow : false})}
                    data={this.state.edit}
                />
            </>
        )
            ;
    }

}

export default Liste;
