import React, {Component} from 'react';
import {Button, Col, Nav, Row, Tab} from "react-bootstrap";
import {DataStore} from 'aws-amplify';
import {Passes} from '../../models';
import {Auth} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Create from "./create";
import Liste from "./liste";
import Password from "./password";
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('fr');

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passes: [],
        };
    }

    loadData = async () => {
        const sub = DataStore.observeQuery(Passes).subscribe(({items}) =>{
            this.setState({passes: items})
        });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <>
                <Authenticator
                    hideSignUp={true}
                >
                    <Tab.Container id="left-tabs-example" defaultActiveKey="liste">
                        <Row className="container">
                            <Col lg={3}>
                                <div>
                                    <Nav
                                        className="flex-column sideBarHeight align-items-center justify-content-center">
                                        <Nav.Link eventKey="liste">Les passes</Nav.Link>
                                        <Nav.Link eventKey="create">Ajouter</Nav.Link>
                                        <Nav.Link eventKey="password">Modifier mot de passe</Nav.Link>
                                        <Button onClick={signOut} variant="danger"> Deconnexion</Button>
                                    </Nav>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <Row>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="create">
                                            <Create/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="liste">
                                            <Liste/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="password">
                                            <Password/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Row>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Authenticator>
            </>
        );
    }
}

export default Dashboard;