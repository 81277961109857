import React , {createRef} from 'react'
class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.wrapper = createRef();
        this.state = {
          name: "",
          phone: "",
          message: "",
        };
      }
     
      // componentDidMount(){
      //   console.log("this.wrapper",this.wrapper)
      // }

      changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };
    
      submitHandler = (e) => {
        e.preventDefault();

      };


    render() { 
      const { name, mail, message } = this.state;

      return (
            <>
   <section className="register-photo">
       <div className="form-container">
           <div className="image-holder"></div>
           <form method="post" onSubmit={this.submitHandler}>
               <h2 className="text-center"><strong>Une question? Une amélioration?</strong></h2>
               <div className="mb-3"><input  className="form-control" type="text" name="name" onChange={this.changeHandler} value={name} id="name" placeholder="Nom" required/></div>
               <div className="mb-3"><input  className="form-control" type="email" name="mail" onChange={this.changeHandler} value={mail} id="mail" placeholder="Email" required /></div>
               <div className="mb-3"><textarea className="form-control" name="message" id="message" onChange={this.changeHandler} value={message}  placeholder="Votre Message" style= {{ minHeight: '160px' , maxHeight: '160px' }} required></textarea></div>
               <div className="mb-3"><button className="btn btn-primary d-block w-100" type="submit">Envoyer</button></div>
           </form>
       </div>
   </section>   
       </>
        );
    }
}
 
export default Contact;

