import React, {Component} from "react";
import {Button, Form} from "react-bootstrap";
import {Passes} from "../../models";
import {DataStore} from "aws-amplify";

class Create extends Component {

    handleSave = async (e) => {
        const passe = new Passes({
            name: e.target.name.value,
            description: e.target.description.value,
            explanation: e.target.explanation.value,
            category: e.target.category.value,
        })
        await DataStore.save(passe);
    }

    render() {


        return (
            <>
                <div className="container text-center" style={{marginTop: "3%"}}>
                    <div className="base_header">
                        <span>
                          <small className="bor_header_left"></small>Ajouter une passe
                          <small className="bor_header_right"></small>
                        </span>
                    </div>
                    <Form onSubmit={this.handleSave}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Entrez un nom" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" type="text" name="description" placeholder="Description" />
                            <Form.Text className="text-muted">
                                Entrez une brève description de la passe
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="explanation">
                            <Form.Label>Explication</Form.Label>
                            <Form.Control as="textarea" type="text" name="explanation" placeholder="Explication" />
                            <Form.Text className="text-muted">
                                Expliquez les mouvements complets de la passe
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category">
                            <Form.Label>Catégorie</Form.Label>
                            <Form.Select name="category">
                                <option value="ouvertures">Les ouvertures</option>
                                <option value="deplacements">Les déplacements</option>
                                <option value="rotations">Les rotations</option>
                                <option value="sorties">Les sorties</option>
                                <option value="breaks">Les breaks</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="success" type="submit">
                            Ajouter
                        </Button>
                    </Form>
                </div>
            </>
        )
            ;
    }
}

export default Create;
