import {FiPhone} from 'react-icons/fi';
import {IoMailOutline} from 'react-icons/io5';

const Footer = () => {
    return (
        <>
            <footer className="footer-dark">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-6 col-md-6 item">
                            <h3 className="footer-links-title">Liens Utiles</h3>
                            <ul className="footer-links">
                                <li><a href="/">Acceuil</a></li>
                                <li><a href="/#step">Pas de base</a></li>
                                <li><a href="/passes">Les passes</a></li>
                                <li><a href="/config">Dashboard</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-4 item">
                            <h3><strong>Contact :</strong></h3>
                            <ul className="footer-links">
                                <li><a><FiPhone style={{fontSize: '25px', paddingRight: '1%'}}/>0781139309</a></li>
                                <li><a><IoMailOutline style={{fontSize: '25px', paddingRight: '1%'}}/>contact@bastiendamour.com</a>
                                </li>
                                <br/>
                            </ul>
                        </div>
                    </div>
                    <p className="copyright">Ramboubou Danse Floor © 2022 / Par <a
                        style={{textDecoration: 'none', color: '#f0f9ff'}} href="https://www.bastiendamour.com">Bastien
                        Damour</a></p>
                </div>
            </footer>
        </>
    )
}
export default Footer; 